<script setup lang="ts">
import { useTemplates } from '@reports/components/Template/composables/useTemplates'
import { DashboardQuery } from '@dashboard'

const divisionId = getCurrentDivisionId()

// Upcoming critical dates link
const { templates } = useTemplates()
const template = computed(() =>
  templates.value.find((template) => template.name === 'Critical Dates'),
)
const link = computed(() => {
  if (!template.value?.id) return

  return {
    path: `/division/${divisionId}/reports/view`,
    query: {
      template: template.value.id,
    },
  }
})

const { data, isPending } = useApiDashboard(
  divisionId,
  DashboardQuery.CRITICAL_DATES,
)
const reminders = computed(() => data.value?.results?.data ?? [])
const isEmpty = computed(() => reminders.value.length === 0)
</script>

<template>
  <DashboardWidget :loading="isPending" :is-empty="isEmpty">
    <template #header>
      <h3 class="text-base font-medium leading-7 text-white">
        Upcoming
        <NuxtLink
          :to="link"
          class="text-primary underline hover:cursor-pointer"
        >
          Critical Dates
        </NuxtLink>
      </h3>
    </template>

    <div class="flex max-h-[350px] flex-col gap-3 overflow-auto">
      <DashboardReminder
        v-for="(reminder, idx) in reminders"
        :id="reminder.leaseId"
        :key="idx"
        :name="reminder.leaseName"
        :type="reminder.criticalDateType"
        :date="reminder.date"
        :division-id="divisionId"
      />
    </div>
  </DashboardWidget>
</template>
